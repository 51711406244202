import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css'
import { ReactComponent as Logo } from '../svg/agg_logo.svg';
function Footer(props) {

    return (
        <footer className="Footer">
            <div className="FooterWrap">
                <div className="FooterLogoWrap">
                    <a href="#Home" className="Flogo"><Logo className="LogoSVG"/></a>
                </div>
                <div className="TermsBtn">
                    {/* <Link to="" target="_blank">Terms of Service</Link>
                    <Link to="" target="_blank">Privacy Policy</Link> */}
                    <Link to="https://whitepaper.alphagg.io/" target="_blank">White paper</Link>
                </div>
                <div className="FooterBtnWrap">
                <Link to="https://discord.gg/zoidswild" target="_blank"><img src="https://data.actgames.co.kr/agg/images/sns_discord.png" /></Link>
                <Link to="https://p2eall.com/ko/guild/detail/ZOIDS_WILD_ARENA/mcrlabs/home" target="_blank"><img src="https://data.actgames.co.kr/agg/images/sns_p2e.png" /></Link>
                <Link to="https://twitter.com/AGG_GAME?t=plc-mXMpsDQGdavOCx0w6g&s=09" target="_blank"><img src="https://data.actgames.co.kr/agg/images/sns_x.png" /></Link>
                </div>
            </div>
            <div className="CopyRightWrap">
                <p className="CopyRight">© TOMY/ZW,TX © TOMY/ZW,MBS © TOMY. © 2015,2020 SANRIO CO., LTD. © 2020 FUJIYA CO., LTD.© TAITO CORPORATION 1986, 2021 © Panda’s ana © ACT Games Co., Ltd. All Rights Reserved.</p>
            </div>
        </footer>
    );
}
export default Footer;

