import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import '../css/header.css'
import { ReactComponent as Logo } from '../svg/agg_logo.svg';

function Header(props) {
  const tarGet = document.getElementById("HeaderBgColor");
  
  function CloseMobileMenu() {
    const headerCheckBox = document.getElementById("Nav");
    headerCheckBox.checked = false;
  }
window.onscroll = function () {
  scrollChangeColorFunction();
};
function scrollChangeColorFunction() {

  if (document.documentElement.scrollTop < 100) {
    document.getElementById("HeaderBgColor").style.opacity = '0';
    document.getElementById("HeaderMenu").style.color ='#fff';
  } else {
    document.getElementById("HeaderBgColor").style.opacity = '1';
    document.getElementById("HeaderMenu").style.color ='#000';
  }
}
const handleMouseMove = (e) => {
  document.getElementById("HeaderBgColor").style.opacity = '1';
}
const handleMouseLeave = (e) => {
  document.getElementById("HeaderBgColor").style.opacity = '0';
}
    return (
      <header className="HeaderWrap"  onMouseMove={(e) => handleMouseMove(e)} onMouseLeave={(e) => handleMouseLeave(e)}>
        <div className="NavWrap">
        <span id="HeaderBgColor"></span>
          <a id="HeaderLogoColor" to="/" href="#Home" className="Hlogo" onClick={() => { CloseMobileMenu() }}><Logo className="LogoSVG"/></a>
          <input type="checkbox" id="Nav" className="hidden" />
          <label htmlFor="Nav" className="NavOpen"><i></i><i></i><i></i></label>
          <div className="NavContainer">
            <div className="NavContainerWrap" id="HeaderMenu">
              <ul>
                <li><a href="#Home" className="MenuBtn" onClick={() => { CloseMobileMenu() }}>Home</a></li>
                <li><a href="#WeAre" className="MenuBtn" onClick={() => { CloseMobileMenu() }}>We Are</a></li>
                <li><a href="#OurForte" className="MenuBtn" onClick={() => { CloseMobileMenu() }}>Our Forte</a></li>
                <li><a href="#OurPartners" className="MenuBtn" onClick={() => { CloseMobileMenu() }}>Our Partners</a></li>
              </ul>
            </div>
            <div className="Dropdown">
                  <Link to="/" className="Dropbtn" onClick={() => { CloseMobileMenu() }}>Product</Link>
                    <div className="DropDownContent">
                      <Link to="https://egg.alphagg.io/" target='_blank' className="MenuBtn"  onClick={() => { CloseMobileMenu() }}>AGG Swap - Casual</Link>
                      <Link to="https://zoidsnft.io/" target='_blank' className="MenuBtn" onClick={() => { CloseMobileMenu() }}>AGG Swap - TCG</Link>
                    </div>
                  </div>
          </div>
         
        </div>
        
        <div className="ColorLine"></div>
      </header>
    );
}

export default Header;
