import React, {useEffect , useState} from "react";
function NotFound() {
  return (
    <section className="NotFound">
      <div className="NotFoundWrap">
        <h1>PAGE NOT FOUND</h1>
        <h6>There's nothing here</h6>
      </div>
    </section>
  );
}

export default NotFound;
