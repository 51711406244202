import React, {useEffect , useState, useRef} from "react";
import './css/partners.css'
function OurForte() {
  return (
    <section className="OurForte" id="OurForte">
      <div className="OurForteWrap">
      <h4>Our Forte</h4>
          <div className="ForteWrap Col">
            <div className="ImageWrap">
            <img  className="ContentImages" src="https://data.actgames.co.kr/agg/images/ourforte_01.png"/>
              {/* <img  className="Overray" src="https://data.actgames.co.kr/agg/images/ourforte_bg_color.png"/> */}
            </div>
            <div className="TextWrap TextAlineLeft">
              <h5>IP MANAGEMENT</h5>
              <p>Working with globally renowned character brands</p>
            </div>
          </div>
          <div className="ForteWrap ColRe">
            <div className="TextWrap TextAlineRight">
              <h5>Efficiency</h5>
              <p>Saved 50+% of dev cost by utilizing assets from IP holder</p>
            </div>
            <div className="ImageWrap">
            <img  className="ContentImages" src="https://data.actgames.co.kr/agg/images/ourforte_02.png"/>
            {/* <img  className="Overray" src="https://data.actgames.co.kr/agg/images/ourforte_bg_color.png"/> */}
            </div>
          </div>
          <div className="ForteWrap Col">
            <div className="ImageWrap">
            <img  className="ContentImages" src="https://data.actgames.co.kr/agg/images/ourforte_03.png"/>
            {/* <img  className="Overray" src="https://data.actgames.co.kr/agg/images/ourforte_bg_color.png"/> */}
            </div>
            <div className="TextWrap TextAlineLeft">
              <h5>Network</h5>
              <p>Utilize existing pipeline of IP holders; IP fans: Community & media platforms</p>
            </div>
          </div>
          <div className="ForteWrap ColRe">
          <div className="TextWrap TextAlineRight">
              <h5>Technology</h5>
              <p>Integrating WEB 3.0, Ai, and other trending tech in game</p>
            </div>
            <div className="ImageWrap">
            <img  className="ContentImages" src="https://data.actgames.co.kr/agg/images/ourforte_04.png"/>
            {/* <img  className="Overray" src="https://data.actgames.co.kr/agg/images/ourforte_bg_color.png"/> */}
            </div> 
          </div>
      </div>
      <div className="BGPattern100"></div>
    </section>
  );
}

export default OurForte;
