import React, {useEffect , useState} from "react";
function Proof() {
  return (
    <section className="NotFound">
      <div className="NotFoundWrap">
        <h1>892442</h1>
      </div>
    </section>
  );
}

export default Proof;
