import React, {useEffect , useState} from "react";
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';
import {Home} from './Home';
import Proof from './Proof';
import NotFound from './NotFound';
import { Translation } from "react-i18next";

// import Eng_privacy from "./terms/Eng_privacy";
// import Eng_service from "./terms/Eng_service";
// import Eng_provision from "./terms/Eng_provision";


function MainLayout() {
	return (
		<>
		 <Translation>{t => <Header translation={t} />}</Translation>
			<Outlet></Outlet>
		 <Translation>{t => <Footer translation={t} />}</Translation>
		</>
	)
}



function App() {

	useEffect(() => {
		// if (window.location.href != window.origin + '/') {
		// 	window.location.href = window.origin;
		// } 
	  }, [])
	
  return (
	  <>
		  <BrowserRouter >
			  <Routes>
				  <Route element={<MainLayout />}>
					  <Route path="/" element={<Translation>{t => <Home translation={t}/>}</Translation>} />
				  </Route>
				  {/* <Route exact={true} path="/eng/sub/privacy.php" element={<Eng_privacy/>} />
				  <Route exact={true} path="/eng/sub/provision.php" element={<Eng_provision/>} />
				  <Route exact={true} path="/eng/sub/service.php" element={<Eng_service/>} /> */}
				    <Route path="/proof" element={<Proof/>} />
					<Route path="*" element={<NotFound />}/>
			  </Routes>
		  </BrowserRouter>
	  </>
  );
}

export default App;
