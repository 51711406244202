import React, {useEffect , useState , useRef , Suspense} from "react";
import {isMobile} from 'react-device-detect';
import { Canvas , useFrame } from '@react-three/fiber'
import { useGLTF  , OrbitControls} from '@react-three/drei'

import './css/aboutagg.css'

function Aboutagg() {

  function AggToken({ ...props }) {
    const ref = useRef()
    const { nodes, materials } = useGLTF('/agg_token.glb')
    useFrame((state) => {
      const t = state.clock.getElapsedTime()
      // ref.current.rotation.set(89.6, 5.35, 0)
      ref.current.rotation.set(89.6, 5.35, 0)
    })
    return (
      <group {...props} dispose={null}>
        <group ref={ref}>
          <group position={[0, 0,0]} rotation={[0, 0, 0]}>
            <mesh castShadow geometry={nodes.aggtoken.geometry} material={materials['skin']} />
          </group>
        </group>
      </group>
    )
  }
  
  return (
    <>
    <section className="AboutAgg" id="WeAre">
    <img className="Light Screen" src="https://data.actgames.co.kr/agg/images/weare_overay.png"/>
    <img className="Light Overray" src="https://data.actgames.co.kr/agg/images/weare_overay.png"/>
    {/* <img className="Light GamesImages" src="https://data.actgames.co.kr/agg/images/weare_overay_game.png"/> */}
      <div className="AboutAggWrap">
        <div className="TextWrap">
          <h6>We are</h6>
          <h5>Web3 toolbox powering global IPs & gaming</h5>
          <p>AGG's primary purpose is to connect various IP-based game titles,enabling innovation within the gaming industry.</p>
        </div>
      </div>
      <Canvas className="AggToken" shadows dpr={[1, 2]} camera={{ position: [0, 0, 1.3], fov: 50 }} style={{ pointerEvents: isMobile ? "none": "visible"}}>
      <ambientLight intensity={1.9} />
    <directionalLight position={[0, 100, 0]} intensity={19} color="#9db7ff" />
      <Suspense fallback={null}>
        <AggToken scale={0.28} position={[0, 0.15, 0]}/>
      </Suspense>
    <OrbitControls autoRotate enableZoom={false} />
    </Canvas>
    </section>
    </>
  );
}

export default Aboutagg;
