import React, {useEffect , useState, useRef} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import './css/ourForte.css'

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay,FreeMode } from 'swiper/modules';

function Partners() {

const swiperSpeed = 1500;
const autoPlayDelay = 1000;

  return (
    <section className="Partners" id="OurPartners">
      <h4>Our Partners</h4>
      <div className="PartnersTopSwiperWrap">
      <Swiper
        centeredSlides={true}
        loop={true}
        speed={swiperSpeed}
        allowTouchMove={false}
        autoplay={{
          delay: autoPlayDelay,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        modules={[Autoplay,FreeMode]}
        breakpoints={{
          310:{slidesPerView:2},470:{slidesPerView:3},630:{slidesPerView:4},1110:{slidesPerView:4},1430:{slidesPerView:5},1750:{slidesPerView:6},2550:{slidesPerView:8}
        }}
        className="TopSwiper"
      >
        {/* <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_21.png"/></SwiperSlide> */}
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_1.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_2.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_3.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_4.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_5.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_6.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_7.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_8.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_9.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_10.png"/></SwiperSlide>
      </Swiper> 
      </div>
      <div className="PartnersBottomSwiperWrap">
      <Swiper
        centeredSlides={true}
        loop={true}
        speed={swiperSpeed}
        allowTouchMove={false}
        autoplay={{
          delay: autoPlayDelay,
          disableOnInteraction: false,
        }}
        spaceBetween={30}
        modules={[Autoplay,FreeMode]}
        breakpoints={{
          310:{slidesPerView:2},470:{slidesPerView:3},630:{slidesPerView:4},1110:{slidesPerView:4},1430:{slidesPerView:5},1750:{slidesPerView:6},2550:{slidesPerView:8}
        }}
        className="BottomSwiper"
        dir="rtl"
      >
        
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_11.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_12.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_13.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_14.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_15.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_16.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_17.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_18.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_19.png"/></SwiperSlide>
        <SwiperSlide><img src="https://data.actgames.co.kr/agg/images/partners_20.png"/></SwiperSlide>
      </Swiper> 
      </div>
      <div className="ButtonWrap">
      <a href="mailto:contact@mcrlabs.io" className="ContactBtn"><img/><p>Contact Us</p></a>
      </div>
    </section>
  );
}

export default Partners;
