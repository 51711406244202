import * as THREE from 'three'
import {isMobile, isSafari , isMacOs} from 'react-device-detect';
import React, {useEffect , useState, useRef , useTransition} from "react";
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Canvas, useThree, useFrame } from '@react-three/fiber'
import { useGLTF, Edges, Environment , Points, PointMaterial  } from '@react-three/drei'
import { Physics, useCompoundBody, useCylinder } from '@react-three/cannon'
import { LayerMaterial, Depth } from 'lamina'
import { EffectComposer, N8AO, Bloom } from "@react-three/postprocessing"
import * as random from 'maath/random/dist/maath-random.esm'
import AboutAgg from './Aboutagg'
import OurForte from './OurForte'
import Partners from './Partners'

console.log("isMobile" + isMobile);
console.log(isSafari)


let totalBauble = null;

if(isMobile){
   totalBauble = 3;
}else{
  if(isMacOs){
    totalBauble = 5;
  }else{
    totalBauble = 9;
  }
  
}

const vec = new THREE.Vector3()
const white = new THREE.MeshBasicMaterial({ color: '#fefefe', toneMapped: false })
const black = new THREE.MeshBasicMaterial({ color: 'black', toneMapped: false })
// const baubleMaterial = Array.from({ length: totalBauble }, () => (new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff, emissive: "red", transparent:true , opacity: 0.5 })))
// const baubleMaterial = Array.from({ length: totalBauble }, () => (new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff, emissive: Math.random() * 0xffffff, transparent:true , opacity: 0.5 })))
// const baubleMaterial = Array.from({ length: totalBauble }, () => (new THREE.MeshLambertMaterial({ color: niceColors[totalBauble][Math.floor(Math.random() * 6)], emissive: niceColors[totalBauble][Math.floor(Math.random() * 6)], transparent:true , opacity: 0.6 })))
const colors = ["#f2514c","#ff0100","#ffd92c","#ffb924","#abec27","#73b84d","#d0faff","#149ddb","#f506f8","#f806a0"]
const emissivecolors= ["#000000","#303030","#555555","#7c7c7c","#afafaf"]
const baubleMaterial = Array.from({ length: totalBauble }, () => (new THREE.MeshLambertMaterial({ color: colors[Math.floor(Math.random()*colors.length)], emissive: emissivecolors[Math.floor(Math.random()*emissivecolors.length)], transparent:true , opacity: 0.35 })))
const sphereGeometry = new THREE.SphereGeometry(1, 28, 28)


export const Home = ({ amount = totalBauble }) => (
  <>
    <div className="SectionMain" id="Home">
    <div className="SectionMainWrap">
      <div className="OverlayWrap" style={{ zIndex: isMobile ? 2: 0}}>
        <div className="TextWrap">
        <h2>AGG: The Future of <span>Web3</span> Gaming</h2>
        <h6>Enter AGG, the game-changing token powering<span className=""> Web3 gaming and IP-based </span>entertainment.</h6>
        <p>Scroll to explore</p>
        </div>
      </div>
      <div className="ArrowWrap">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <Canvas className="MainCanvas" dpr={[1, 2]} camera={{ position: [0, 0, 5], fov: 50 }}>
    <ambientLight intensity={1.8} />


  {!isMacOs && <directionalLight position={[0, 35, -14]} intensity={4.8} />}
    
    <Physics gravity={[0, 0, 0]}>
      {Array.from({ length: amount }, (_, i) => {
        const El = i % 3 ? Vercel : Vercel
        return <El key={i} mass={4} angularDamping={0.4} linearDamping={0.8} position={[Math.random(), Math.random(), Math.random()]}/>
      })}
      <Cursor mass={150} angularDamping={0.5} linearDamping={0.5} position={[0, 0, 10]} />
    </Physics>
    <EffectComposer multisampling={0}>
    <Bloom luminanceThreshold={0.1} mipmapBlur luminanceSmoothing={0.0} intensity={1.2} />
      {!isMacOs && <N8AO color={'#fff'}aoRadius={1} intensity={3}/> }
    </EffectComposer>
  </Canvas>
    </div>
  </div>
  <div className="DumyWrap"></div>
  <div className="BackgroundWrap">
<AboutAgg/>
<OurForte/>
<Partners/>
<div className="StarsBackground"></div>
</div>
  </>
)

function Vercel(props) {

  const [ref, api] = useCylinder(() => ({ args: [0.65, 0.65, 0.65, 3], ...props }))
  useFrame(() => api.applyForce(vec.setFromMatrixPosition(ref.current.matrix).normalize().multiplyScalar(-40).toArray(), [0, 0, 0]))
  
  return (
    <>
<group ref={ref}>
      <mesh scale={[0.6, 0.6, 0.6]} position={[-0.02, -0.5, 0.022]}  geometry={sphereGeometry} material={baubleMaterial[Math.floor(Math.random() * totalBauble)]} castShadow>
        <Edges scale={1.005} material={black} />
      </mesh>
    </group>
    </>
  )
}

function Cursor({ speed = 12, gradient = 0.75, ...props }) {
  const { nodes } = useGLTF('/agg.glb')
  const viewport = useThree((state) => state.viewport)
  const [ref, api] = useCompoundBody(() => ({
    ...props,
    shapes: [
      { type: 'Cylinder', args: [0.6, 0.6, 0.5, 3], position: [0, 0.2, 0], rotation: [Math.PI / 2, Math.PI, 0] },
      { type: 'Box', args: [0.25, 1, 0.3], position: [0, -0.45, 0] }
    ]
  }))
  useFrame((state) => {
    vec.setFromMatrixPosition(ref.current.matrix)
    api.velocity.set(((state.mouse.x * viewport.width) / 2 - vec.x) * speed, ((state.mouse.y * viewport.height) / 2 - vec.y) * speed, -vec.z)
  })


  return (
    <>
    <group ref={ref}>
      <mesh scale={[2.5, 2.5, 2.5]} rotation={[0, Math.PI / 2, 0]} geometry={nodes.Cube.geometry}>
        <LayerMaterial toneMapped={false}>
          <Depth colorA="red" colorB="black" alpha={1} mode="normal" near={0.5 * gradient} far={0.5} origin={[0, 0, 0]} />
          <Depth colorA="blue" colorB="#ffc835" alpha={1} mode="add" near={2 * gradient} far={2} origin={[1, 1, 1]} />
          <Depth colorA="green" colorB="#f7b955" alpha={1} mode="add" near={3 * gradient} far={3} origin={[-1, -1, -1]} />
          <Depth colorA="white" colorB="#f0606d" alpha={1} mode="overlay" near={1.5 * gradient} far={1.5} origin={[1, -1, -1]} />
        </LayerMaterial>
        <Edges scale={1.04} color="white" />
      </mesh>
    </group>
    </>
  )
}



